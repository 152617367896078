const MyQuestionReduser = (state = "", action) => {
    switch (action.type) {
        case 'USER_ALL_QUESTION_SUCCESS':
            return action.payload.res.data
        case 'QUESTION_ANSWER_DELETE_SUCCESS':
            return state.filter((data) => data.ID !== action.payload.data?.Qid)
        default:
            return state
    }
}
export default MyQuestionReduser