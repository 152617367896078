import axios from "axios"
import { toast } from "react-toastify"
// const ROOTURL = "https://api.perisync.com"
// const ROOTURL = "https://pstproxy.herokuapp.com/https://askmufti.perisync.com"
const ROOTURL = "https://askmufti.perisync.com"
// const ROOTURL = "http://192.168.0.100:15020"
// const ROOTURL = "http://192.168.0.103:3002"
// const ROOTURL = "http://localhost:4000"
// const TempURL = "http://192.168.0.113:4000"


// const sid1 = localStorage.getItem('sid')
// const at1 = localStorage.getItem('at')
// const options = {
//   headers: {
//   sid: localStorage.getItem('sid'),
//   at: localStorage.getItem('at') }
// }

/*eslint-disable */
export const USER_SEND_OTP = ({ ...data }) => {
  return (dispatch) => {
    axios
      .post(ROOTURL + "/user/account/login/sendOTP", {
        ...data
      })
      .then((res) => {
        dispatch({
          type: "VAILDATE_NUM",
          payload: { ...data, res }
        })
      })
      .catch((err) => {
        dispatch({
          type: "VAILDATE_NUM_FAILED",
          msg: err
        })
      })
  }
}
export const USER_VERIFY_EMAIL = ({ ...data }) => {
  return (dispatch) => {
    axios
      .post(`${ROOTURL}/account/login/checkEmail?type=web`, {
        ...data
      })
      .then((res) => {
        dispatch({
          type: "VAILDATE_EMAIL",
          payload: { ...data, res }
        })
      })
      .catch((err) => {
        toast.error(err.response?.data?.Description, { autoClose: 3000 })
        dispatch({
          type: "VAILDATE_EMAIL_FAILED",
          msg: err
        })
        // console.log("error",err.response);
      })
  }
}


export const VALIDATE_OTP = ({ ...data }) => {
  return (dispatch) => {
    axios
      .post(ROOTURL + "/account/login/verifyOTP", {
        ...data,
      })
      .then((res) => {
        dispatch({
          type: "VAILDATE_OTP",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "VAILDATE_OTP_Failed",
          msg: err,
        });
      });
  };
};
export const VALIDATE_PASSWORD = ({ ...data }) => {
  return (dispatch) => {
    axios
      .post(ROOTURL + "/account/login/verifyPswd", {
        ...data,
      })
      .then((res) => {
        localStorage.setItem('ID', res?.data?.UserInfo?.Role?.ID),
          dispatch({
            type: "VAILDATE_PASSWORD",
            payload: {
              ...data,
              res,
            },
          });
      })
      .catch((err) => {
        toast.error(err.response.data.Description, { autoClose: 3000 })
        // console.log(err.response.data.Description)
        dispatch({
          type: "VAILDATE_PASSWORD_Failed",
          msg: err,
        });
      });
  };
};

export const GETNEW_TOKEN = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      rt: localStorage.getItem('RefreshToken'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios
      .get(ROOTURL + "/api/v1/user/auth/renew", options)
      .then((res) => {
        dispatch({
          type: "NEWTOKEN_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "REFRESH_EXPIRED",
          msg: err,
        });
      });
  };
};

export const logOutAPi = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios
      .post(ROOTURL + "/api/v1/user/account/logout/one", {
        ...data
      }, options)
      .then((res) => {
        dispatch({
          type: "LOGOUTAPI_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "LOGOUT_Failed",
          msg: err,
        });
      });
  };
};

export const GET_NOTIFICATION = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/user/notification/get", options)
      .then((res) => {
        dispatch({
          type: "GET_NOTIFICATION_SUCCESS",
          payload: {
            ...data, res
          }
        });
      })
      .catch((err) => {
        if (err?.response?.status === 403) dispatch({ type: "VALID_USER_CHECK_FAIL", msg: "true" })
        dispatch({
          type: "GET_NOTIFICATION_FAILED",
          msg: { ...data, err }
        });
      });
  };
};
export const NotificationSingleClear = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.post(ROOTURL + "/api/v1/user/notification/delete", {
      ...data,
    }, options)
      .then((res) => {
        if (data.Notifications.ID === 0) {
          dispatch({
            type: "DELETE_ALL_NOTI_SUCCESS",
            payload: { ...data, res, },
          });
        } else {
          dispatch({
            type: "DELETE_SINGLE_NOTI_SUCCESS",
            payload: { ...data, res, },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_Noti_FAILED",
          msg: { ...data, err }
        });
      });
  };
};


export const ApproveTeamQAllGet = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/a-team/questions/all", options)
      .then((res) => {
        dispatch({
          type: "GET_ATEAM_QA_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_ATEAM_QA_FAILED",
          msg: { err }
        });
      });
  };
};
export const UserprofileGet = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/user/profile/get", options)
      .then((res) => {
        dispatch({
          type: "USERPROFILE_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "USERPROFILE_FAILED",
          msg: { err }
        });
      });
  };
};
export const ApproveTeamApproveListGet = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/a-team/actions/log/accepted", options)
      .then((res) => {
        dispatch({
          type: "GET_ATEAM_APPROVELIST_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_ATEAM_APPROVELIST_FAILED",
          msg: { err }
        });
      });
  };
};
export const ApproveTeamRejectListGet = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/a-team/actions/log/rejected", options)
      .then((res) => {
        dispatch({
          type: "GET_ATEAM_REJECTEDLIST_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_ATEAM_REJECTEDLIST_FAILED",
          msg: { err }
        });
      });
  };
};
export const ATEAMQuestionApprove = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/a-team/questions/approve", {
      ...data,
    }, options)
      .then((res) => {
        toast("Answer Approved")
        dispatch({
          type: "ATEAM_QUESTION_Approve_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ATEAM_QUESTION_Approve_FAILED",
          msg: { ...data, err }
        });
      });
  };
};
export const ATEAMQuestionReject = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/a-team/questions/reject", {
      ...data,
    }, options)
      .then((res) => {
        toast("Answer Rejected")
        dispatch({
          type: "ATEAM_QUESTION_REJECT_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ATEAM_QUESTION_REJECT_FAILED",
          msg: { ...data, err }
        });
      });
  };
};


export const QUESTIONS_MO_GET = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }

  return async (dispatch) => {
    await axios.get(ROOTURL + "/api/v1/moderator/questions/all", options)
      .then((res) => {
        dispatch({
          type: "GET_QUESTIONS_MO_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_QUESTIONS_MO_FAILED",
          msg: { err }
        });
      });
  };
};
export const GET_MO_ACCEPT_LIST = () => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }

  return async (dispatch) => {
    await axios.get(ROOTURL + "/api/v1/moderator/actions/log/accepted ", options)
      .then((res) => {
        dispatch({
          type: "MO_ACCEPTED_LIST_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MO_ACCEPTED_LIST_FAILED",
          msg: { err }
        });
      });
  };
};
export const GET_MO_REJECTED_LIST = () => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }

  return async (dispatch) => {
    await axios.get(ROOTURL + "/api/v1/moderator/actions/log/rejected", options)
      .then((res) => {
        dispatch({
          type: "MO_REJECTED_LIST_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MO_REJECTED_LIST_FAILED",
          msg: { err }
        });
      });
  };
};
export const ModeratorQuestionApprove = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }

  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/moderator/questions/approve", {
      ...data,
    }, options)
      .then((res) => {
        toast("Question Accepted")
        dispatch({
          type: "MODERATOR_QUESTION_Approve_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MODERATOR_QUESTION_Approve_FAILED",
          msg: { ...data, err }
        });
      });
  };
};
export const ModeratorQuestionREJECT = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/moderator/questions/reject", {
      ...data,
    }, options)
      .then((res) => {
        toast("Question Rejected")
        dispatch({
          type: "MODERATOR_QUESTION_REJECT_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MODERATOR_QUESTION_REJECT_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const ModeratorQuestionREVERT = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.post(ROOTURL + "/api/v1/moderator/questions/undo", {
      ...data,
    }, options)
      .then((res) => {
        toast("Question added to bucket")
        dispatch({
          type: "MODERATOR_QUESTION_UNDO_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MODERATOR_QUESTION_UNDO_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const SIDE_MENU_API = () => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    // axios.get(ROOTURL + "/api/v1/admin/master/menu", {
    axios.get(ROOTURL + "/api/v1/user/menu", options
    )
      .then((res) => {
        dispatch({
          type: "SIDE_MENU_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SIDE_MENU_FAILED",
          msg: { err }
        });
      });
  };
};


export const MuftiWorkingBucket = () => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/bucket", options)
      .then((res) => {
        dispatch({
          type: "MuftiBucket_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiBucket_FAILED",
          msg: { err }
        });
      });
  };
};
export const MuftiWorkingQuestionSelect = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.post(ROOTURL + "/api/v1/mufti/question/select", {
      ...data,
    }, options)
      .then((res) => {
        toast("Question Added")
        dispatch({
          type: "MuftiQuestionSelect_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiQuestionSelect_FAILED",
          msg: { ...data, err }
        });
      });
  };
};
export const MuftiWorkingListCount = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/mybox/counts", options)
      .then((res) => {
        dispatch({
          type: "MuftiCount_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiCount_FAILED",
          msg: { err }
        });
      });
  };
};
export const MuftiWorkingDraft = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/mybox/draft", options)
      .then((res) => {
        dispatch({
          type: "MuftiDraft_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiDraft_FAILED",
          msg: { err }
        });
      });
  };
};
export const MuftiWorkingInbox = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/mybox/inbox", options)
      .then((res) => {
        dispatch({
          type: "MuftiInbox_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiInbox_FAILED",
          msg: { err }
        });
      });
  };
};
export const MuftiWorkingSentItem = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/mybox/approval-queue", options)
      .then((res) => {
        dispatch({
          type: "MuftiSentItem_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiSentItem_FAILED",
          msg: { err }
        });
      });
  };
};

export const MuftiWorkingRejectedList = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/mybox/rejected-list", options)
      .then((res) => {
        dispatch({
          type: "MuftiRejected-list_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiRejected-list_FAILED",
          msg: { err }
        });
      });
  };
};
export const MuftiWorkingApprovedList = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/mufti/mybox/approved-list", options)
      .then((res) => {
        dispatch({
          type: "MuftiApproved_list_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiApproved_list_FAILED",
          msg: { err }
        });
      });
  };
};

export const MuftiWorkingSavetoDraft2 = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.patch(ROOTURL + "/api/v1/mufti/mybox/save-to-draft", {
      ...data,
    }, options)
      .then((res) => {
        toast("Answer Saved")
        dispatch({
          type: "MuftiSavetoDraft2_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiSavetoDraft_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const MuftiWorkingSavetoDraft = ({ ...data }) => {
  console.log("asd", data)
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.patch(ROOTURL + "/api/v1/mufti/mybox/save-to-draft", {
      ...data,
    }, options)
      .then((res) => {
        toast("Answer Saved")
        if (data.autosave) {
          dispatch({
            type: "MuftiAutoSave_SUCCESS",
            payload: { ...data }
          });
        } else {
          dispatch({
            type: "MuftiSavetoDraft_SUCCESS",
            payload: {
              ...data,
              res,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "MuftiSavetoDraft_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const MuftiWorkingSubmitToApproval = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.post(ROOTURL + "/api/v1/mufti/mybox/submit-to-approval", {
      ...data,
    }, options)
      .then((res) => {
        toast("Answer Sent")
        dispatch({
          type: "MuftiSubmitToApproval_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MuftiSubmitToApproval_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const AskMuftiAnswer = ({ ...data }) => {
  return (dispatch) => {
    axios.get(ROOTURL + `/answer/${data.ID}`)
      .then((res) => {
        dispatch({
          type: "AskMuftiAnswer_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "AskMuftiAnswer_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const MY_QUESTIONS_GET = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/user/questions/all", options)
      .then((res) => {
        dispatch({
          type: "MY_QUESTIONS_GET_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "MY_QUESTIONS_GET_FAILED",
          msg: { err }
        });
      });
  };
};
export const UserAskQuestion = (formData) => {
  const options = {
    headers: {
      "Content-type": "multipart/form-data",
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    // axios.post(ROOTURL + "/user/questions/ask", {
    //   formData,headers: { "Content-Type": "multipart/form-data" }
    //   })
    axios.post(ROOTURL + "/api/v1/user/questions/ask", formData, options)
      .then((res) => {
        toast("Question Added")
        dispatch({
          type: "USER_ASK_QUESTION_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "USE_ASK_QUESTION_FAILED",
          msg: { err }
        });
      });
  };
};
export const UserALLQuestion = ({ ...data }) => {
  return (dispatch) => {
    axios.get(ROOTURL + "/question/all", {
      ...data,
    })
      .then((res) => {
        dispatch({
          type: "USER_ALL_QUESTION_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "USER_ALL_QUESTION_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const DraftQuestionListAPI = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/mufti/question/list", options)
      .then((res) => {
        dispatch({
          type: "DRAFT_QUESTION_LIST_SUCCESS",
          payload: {
            ...data,
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "DRAFT_QUESTION_LIST_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const RevokeDraftQuestionAPI = (data, { setBasicModal, setloader }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/admin/mufti/question/revoke", data, options)
      .then((res) => {
        setBasicModal(false)
        setloader(false)
        dispatch({
          type: "REVOKE_FROM_DRAFT_SUCCESS",
          payload: {
            data,
            res,
          },
        });
      })
      .catch((err) => {
        setBasicModal(false)
        setloader(false)
        dispatch({
          type: "REVOKE_FROM_DRAFT_FAILED",
          msg: { data, err }
        });
      });
  };
};

export const DeleteQAbyAdminApi = (data) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.delete(`${ROOTURL}/api/v1/admin/answer/delete?ansID=${data?.id}`, options)
      .then((res) => {
        dispatch({
          type: "QUESTION_ANSWER_DELETE_SUCCESS",
          payload: {
            data,
            res,
          },
        });
        toast("Action Completed Sucessfully")
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_ANSWER_DELETE_FAILED",
          msg: { data, err }
        });
        toast("Something went Wrong")
      });
  };
};

export const ADMIN_LOGHISTORY = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/user/login/history", options)
      .then((res) => {
        dispatch({
          type: "ADMIN_LOGHISTORY_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADMIN_LOGHISTORY_FAILED",
          msg: { err }
        });
      });
  };
};
export const ADMIN_LOGINHISTORY = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/login-history", options)
      .then((res) => {
        dispatch({
          type: "ADMIN_LOGINHISTORY_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADMIN_LOGINHISTORY_FAILED",
          msg: { err }
        });
      });
  };
};
export const ADMIN_AUDITLOGIN = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/user/login/history", options)
      .then((res) => {
        dispatch({
          type: "ADMIN_AUDITLOGIN_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADMIN_AUDITLOGIN_FAILED",
          msg: { err }
        });
      });
  };
};
export const ADMIN_LIVEUSERS = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/live-users", options)
      .then((res) => {
        dispatch({
          type: "ADMIN_LIVEUSERS_SUCCESS",
          payload: {
            res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADMIN_LIVEUSERS_FAILED",
          msg: { err }
        });
      });
  };
};
export const AdminUserGetAll = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/users/get/all", options)
      .then((res) => {
        dispatch({
          type: "AdminUserGetAll_SUCCESS",
          payload: { res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "AdminUserGetAll_FAILED",
          msg: { err }
        });
      });
  };
};
export const AdminUserGetMufti = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/users/get/1", options)
      .then((res) => {
        dispatch({
          type: "AdminMuftiGetList_SUCCESS",
          payload: { res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "AdminMuftiGetList_FAILED",
          msg: { err }
        });
      });
  };
};
export const AdminMuftiManagementADD = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.post(ROOTURL + "/api/v1/admin/user/create", {
      ...data,
    }, options)
      .then((res) => {
        toast("User Added")
        dispatch({
          type: "ADMIN_CREATE_USER_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADMIN_CREATE_USER_FAILED",
          msg: { ...data, err }
        });
      });
  };
};
export const AdminMuftiManagementUpdate = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/admin/user/update", {
      ...data,
    }, options)
      .then((res) => {
        toast("User Updated")
        dispatch({
          type: "ADMIN_UPDATE_USER_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADMIN_UPDATE_USER_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const AdminUserPasswordChange = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/admin/user/password/change", {
      ...data,
    }, options)
      .then((res) => {
        toast("PassWord Updated")
        dispatch({
          type: "PASSWORD_UPDATE_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "PASSWORD_UPDATE_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const ModeratorQuestionTranslate = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/api/v1/translate", {
      ...data,
    }, options)
      .then((res) => {
        dispatch({
          type: "QUESTION_TRANSLATE_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTION_TRANSLATE_FAILED",
          msg: { ...data, err }
        });
      });
  };
};


export const CategoryManagementRootAPI = ({ setspinner }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/category/root")
      .then((res) => {
        setspinner(false)
        dispatch({
          type: "GET_ROOT_LEVEL_CATEGORY_SUCCESS",
          payload: { res },
        });
      })
      .catch((err) => {
        setspinner(false)
        dispatch({
          type: "GET_ROOT_LEVEL_CATEGORY_FAILED",
          msg: { err }
        });
      });
  };
};


export const RootCategoryDeleteAPI = ({ ...data }) => {
  console.log(data)
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.delete(ROOTURL + "/category/delete", { data })
      .then((res) => {
        dispatch({
          type: "DELETE_ROOT_LEVEL_CATEGORY_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_ROOT_LEVEL_CATEGORY_FAILED",
          msg: { ...data, err }
        });
      });
  };
};


export const RootCategoryAddAPI = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.post(ROOTURL + "/category/create", { ...data })
      .then((res) => {
        dispatch({
          type: "ADD_ROOT_LEVEL_CATEGORY_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADD_ROOT_LEVEL_CATEGORY_FAILED",
          msg: { ...data, err }
        });
      });
  };
};


export const RootCategoryUpdateAPI = ({ ...data }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.put(ROOTURL + "/category/update", { ...data })
      .then((res) => {
        dispatch({
          type: "UPDATE_ROOT_LEVEL_CATEGORY_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "UPDATE_ROOT_LEVEL_CATEGORY_FAILED",
          msg: { ...data, err }
        });
      });
  };
};


export const CategoryManagementSubAPI = ({ ...data }, { setspinner }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + `/category/byParent?pid=${data.pid}`, { ...data })
      .then((res) => {
        setspinner(false)
        dispatch({
          type: "GET_SUB_ROOT_LEVEL_CATEGORY_SUCCESS",
          payload: { ...data, res },
        });
      })
      .catch((err) => {
        setspinner(false)
        dispatch({
          type: "GET_SUB_ROOT_LEVEL_CATEGORY_FAILED",
          msg: { ...data, err }
        });
      });
  };
};

export const CategoryQuestionAPI = (id) => {
  return (dispatch) => {
    axios.get(ROOTURL + `/category/questionByCategory/${id}`)
      .then((res) => {
        dispatch({
          type: "CATEGORY_QUESTION_SUCCESS",
          payload: {
            res
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "CATEGORY_QUESTION_FAILED",
          msg: { err }
        });
      });
  };
};


export const CategorysRootAllListAPI = (setloading) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/category/listall")
      .then((res) => {
        setloading(false)
        dispatch({
          type: "GET_ROOT_CATEGORYS_FOR_MUFTI_SUCCESS",
          payload: { res },
        });
      })
      .catch((err) => {
        setloading(false)
        dispatch({
          type: "GET_ROOT_CATEGORYS_FOR_MUFTI__FAILED",
          msg: { err }
        });
      });
  };
};

export const GenralStatsAPI = () => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/general/insights", options)
      .then((res) => {
        dispatch({
          type: "GENRAL_STATS_SUCCESS",
          payload: { res }
        });
      })
      .catch((err) => {
        dispatch({
          type: "GENRAL_STATS_FAILED",
          msg: { err }
        });
      });
  };
};

export const ModratorInsightsAPI = ({ setl1 }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/moderator/insights", options)
      .then((res) => {
        setl1(false)
        dispatch({
          type: "MODERATOR_INSIGHTS_SUCCESS",
          payload: { res }
        });
      })
      .catch((err) => {
        setl1(false)
        dispatch({
          type: "MODERATOR_INSIGHTS_FAILED",
          msg: { err }
        });
      });
  };
};

export const MufitInsightsAPI = ({ setl2 }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/mufti/insights", options)
      .then((res) => {
        setl2(false)
        dispatch({
          type: "MUFTI_INSIGHTS_SUCCESS",
          payload: { res }
        });
      })
      .catch((err) => {
        setl2(false)
        dispatch({
          type: "MUFTI_INSIGHTS_FAILED",
          msg: { err }
        });
      });
  };
};

export const ApproverInsightsAPI = ({ setl3 }) => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/api/v1/admin/approver/insights", options)
      .then((res) => {
        setl3(false)
        dispatch({
          type: "APPROVER_INSIGHTS_SUCCESS",
          payload: { res }
        });
      })
      .catch((err) => {
        setl3(false)
        dispatch({
          type: "APPROVER_INSIGHTS_FAILED",
          msg: { err }
        });
      });
  };
};


export const GlobalLanguageApi = () => {
  const options = {
    headers: {
      sid: localStorage.getItem('sid'),
      at: localStorage.getItem('at')
    }
  }
  return (dispatch) => {
    axios.get(ROOTURL + "/language/list", options)
      .then((res) => {
        dispatch({
          type: "GET_LANGUAGE_SUCCESS",
          payload: { res },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_LANGUAGE_FAILED",
          msg: { err }
        });
      });
  };
};



/*eslint-enable */

