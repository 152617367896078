const GetDraft = (state = [], action) => {
    switch (action.type) {
        case 'MuftiBucket_SUCCESS':
            return action.payload.res.data
        case 'MuftiInbox_SUCCESS':
            return action.payload.res.data
        case 'MuftiDraft_SUCCESS':
            return action.payload.res.data
        case 'MuftiSentItem_SUCCESS':
            return action.payload.res.data
        case 'MuftiRejected-list_SUCCESS':
            return action.payload.res.data
        case 'MuftiApproved_list_SUCCESS':
            return action.payload.res.data
        case 'EmptyMuftiWorkingArray':
            return []
        case 'MuftiQuestionSelect_SUCCESS':
            const DataID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataID)
        case 'MuftiSavetoDraft_SUCCESS':
            const DataSaveID = action.payload?.Question?.ID
            const saveAndSubmitFlag = action.payload?.saveAndSubmitFlag
            const sideName = action.payload?.sideName
            if (saveAndSubmitFlag === false && sideName !== 'draft') {
                return state.filter((c) => c.ID !== DataSaveID)
            } else return state
        case 'MuftiSubmitToApproval_SUCCESS':
            const DataApprovalID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataApprovalID)
            return state
        case 'MuftiSavetoDraft2_SUCCESS':
            const DataID2 = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataID2)
        default:
            return state
    }
}
export default GetDraft