import { combineReducers } from "redux"
import GetDraft from "./working/GetDraft"
import MailDetails from "./working/MailDetails"
import SaveToDraft from "./working/SaveToDraft"
import AskMuftiAnswer from "./working/AskMuftiAnswer"
import MufiListCount from "./working/MufiListCount"
import MuftiBucketCount from "./working/MuftiBucketCount"

export default combineReducers({
    GetDraft,
    MailDetails,
    SaveToDraft,
    AskMuftiAnswer,
    MufiListCount,
    MuftiBucketCount
  })