// import { UPDATE_LOGOUT_LOG, GET_NEWTOKEN } from '../../Action-Creators/User-Management/'
// import { toastr } from 'react-redux-toastr'
import { toast } from "react-toastify"
import { GETNEW_TOKEN } from "../../Action-Creators/Api-Management"

const buffer = [] // create an empty array which will be populated with all actions dispatched by Redux

export const jwtAction = store => next => action => {
    buffer.push(action)
    let n = 1
    if (action.type !== undefined) {
        const failedstatus = action.type
        const res = failedstatus.substring(failedstatus.length - 6, failedstatus.length)
        localStorage.setItem('method', failedstatus)
        // if (action?.msg?.err?.response?.status === 403) {
        //     // toastr.warning('TOKEN', 'REFRESH_TOKEN_EXPIRED', { position: 'top-left' })
        //     toast("Token Error")
        //     localStorage.setItem('TimeToken', "Expired")
        //     //---------------------------------------
        //     localStorage.clear()
        //     sessionStorage.clear()
        //     window.location.assign('/')
        //     alert('Your session has expired')
        // }
        if (res === 'FAILED' && action.type !== "GET_NOTIFICATION_FAILED" && action.type !== "LOGOUT_Failed" && action.msg?.err?.response?.status === 403 && n === 1) {
            //if (action.err.response.status === 403) {
                store.dispatch(GETNEW_TOKEN())
                n++
            const theStore = store.getState()
            if (
                // theStore.contactApp.NEWTOKEN
                false
            ) {
                if (!theStore.contactApp.pendingRefreshingToken) {
                    try {
                        if (action.msg.response.status === 403 && action.msg.response.status !== 400 && action.msg.response.status !== 409) {
                            // alert("GET_NEWTOKEN: ") //=========MKB
                            // store.dispatch(GET_NEWTOKEN(theStore.contactApp.NEWTOKEN))
                            console.log("Store error")
                        }
                        if (action.msg.response.status === 401) {
                            // toastr.warning('TOKEN', 'REFRESH_TOKEN_EXPIRED', { position: 'top-left' })
                            toast("Token Error")
                            localStorage.setItem('TimeToken', "Expired")
                            //---------------------------------------
                            localStorage.clear()
                            sessionStorage.clear()
                            window.location.assign('/')
                            alert('Your session has expired')
                        }
                    } catch {

                    }
                }
            }
        }
    } if (action.type === 'NEWTOKEN_SUCCESS') {
        if (action.payload.res.status === 200) {
            // alert('middleware:')//==========MKB
            localStorage.setItem('RefreshToken', action.payload.res.data.rt)
            localStorage.setItem('at', action.payload.res.data.at)
            localStorage.setItem('sid', action.payload.res.data.sid)
            window.location.reload()
        }
        const theStore = store.getState()
        if (
            // theStore.LoginApp.AUTH_PASS &&
            // theStore.LoginApp.NEWTOKEN
            false
        ) {
            if (!theStore.LoginApp.pendingRefreshingToken) {
                const pos = buffer.map(e => e.type).indexOf(localStorage.getItem('method')) - 1
                buffer.map((methodtype, index) => {
                    if (methodtype.type === localStorage.getItem('method')) {
                        const str = methodtype.type
                        const rementhod = str.split("_FAILED")
                        for (let i = pos; i > -1; i -= 1) {
                            store.dispatch({
                                type: rementhod,
                                action: buffer[i](store.dispatch)
                            })
                            break
                        }
                        buffer = []
                    }
                })
            }
        }
    } else if (action.type === 'REFRESH_EXPIRED') {
        // buffer = []
        // store.dispatch(UPDATE_LOGOUT_LOG())
        localStorage.clear()
        sessionStorage.clear()
        window.location.assign('/')
        alert('Your session has expired')
    } else {
        if (buffer.length > 20) {
            buffer.splice(0, buffer.length - 20)
        }
        return next(action)
    }
}

export default jwtAction