const QuestionData = (state = [], action) => {
    switch (action.type) {
        case 'GET_QUESTIONS_MO_RESER':
            return []
        case 'GET_QUESTIONS_MO_SUCCESS':
            return action.payload.res.data
        case 'MO_ACCEPTED_LIST_SUCCESS':
            return action.payload.res.data
        case 'MO_REJECTED_LIST_SUCCESS':
            return action.payload.res.data
        case 'MODERATOR_QUESTION_Approve_SUCCESS':
            const DataID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataID)
        case 'MODERATOR_QUESTION_REJECT_SUCCESS':
            const DataRejectID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataRejectID)
        case 'MODERATOR_QUESTION_UNDO_SUCCESS':
            const DataRejectID2 = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataRejectID2)
        default:
            return state
    }
}
export default QuestionData